<template>
  <div>
    <h1 class="page__title">Создать пользователя</h1>

    <div class="intro-y box p-5 mt-5">
      <form
        method="post"
        class="grid grid-cols-12 gap-6"
        :class="{ 'disabled-form': loading }"
      >
        <div
          v-if="loading"
          class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center"
        >
          <Preloader />
        </div>

        <div class="col-span-6 relative">
          <label for="form-name" class="form-label">Имя</label>
          <input
            id="form-name"
            type="text"
            class="form-control w-full"
            placeholder=""
            required
            autocomplete="off"
            v-model='form.name'
          >
        </div>

        <div class="col-span-6 relative">
          <label for="form-email" class="form-label">Email</label>
          <input
            id="form-email"
            type="email"
            class="form-control w-full"
            placeholder=""
            required
            autocomplete="off"
            v-model='form.email'
          >
        </div>

        <div class="col-span-6 relative">
          <label for="form-password" class="form-label">Пароль</label>
          <input
            id="form-password"
            type="password"
            class="form-control w-full"
            placeholder=""
            required
            autocomplete="off"
            v-model='form.password'
          >
        </div>

        <div class="col-span-6 relative">
          <label for="form-role" class="form-label">Роль</label>
          <select
            name="roles"
            id="form-role"
            class="form-select sm:mr-2"
            required
            autocomplete="off"
            v-model="form.role"
          >
            <option v-for="(opt, optIdx) in roles" :key="optIdx" :value="opt.id">{{ opt.title }}</option>
          </select>
        </div>

        <div class="col-span-12 flex items-center justify-end mt-5">
          <router-link :to="{ name: 'users' }" class="btn btn-secondary w-24 mr-2 mb-2">Отмена</router-link>
          <button type="button" class="btn btn-primary mr-2 mb-2" @click="() => save(true)">
            Сохранить и продолжить
          </button>
          <button type="button" class="btn btn-primary mb-2" @click="save(false)">Сохранить</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import SimpleTable from '@/components/tables/SimpleTable.vue';
import Preloader from '@/components/preloader/Main.vue';
import {usersMixin} from '@/mixins/form/users-mixin.js';
import {errorResponse} from '@/mixins/form/form-mixin.js';

export default {
    components: { SimpleTable, Preloader },
    name: 'Create',
    mixins: [usersMixin, errorResponse],
    async created() {
      await this.fetchRoles();
    },
    methods: {
      save(flag) {
        this.loading = true;
        this.axios.post('/users', { ...this.form })
          .then(rea => {
            this.loading = false;

            if(flag){
              this.$router.push({ name: 'userEdit',params:{ id: rea.data.data.id}})
              this.$notify('Запись добавлена');
            }else {
               this.$router.push({name: 'users'});
            }
          })
          .catch(ex => {
            this.errorResponse(ex);
          });
      },
    },
  };
</script>

<style scoped lang="scss">

</style>
